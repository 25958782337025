import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isMobile from '../../services/isMobile';
import { toFormattedText } from '../../services/formatting';
import { colors, fontSizes, mobileThresholdPixels, Content, LineH }
  from '../../components/Home/v2/StyledComponents';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.navy};
  background-color: ${colors.purpleGrey};
`;

const StyledContent = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 68vh;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 26vh;
  }
`;

const Title1 = styled.h1`
  font-size: ${fontSizes.m};
  display: flex;
  flex-direction: column;
  font-weight: normal;
  margin: 0px;
`;

const Title2 = styled.h2`
  display: flex;
  font-weight: normal;
  margin: 0px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 40vw;
  letter-spacing: 1.3px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
  }
`;

const Span1 = styled.span`
  font-family: Roboto;
  font-size: ${props => props.small ? fontSizes.xl : fontSizes.x2l};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${props => props.small ? fontSizes.s : fontSizes.m};
  }
`;

const Span2 = styled.span`
  font-family: Gotham;
  font-size: ${fontSizes.x3l};
  font-weight: bold;
  text-transform: uppercase;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const OurArtisansHeaderBlock = ({ headerImage, name, nickname }) => (
  <Container>
    <StyledContent backgroundImage={headerImage}>
      <TextContainer>
        <Title1>
          <Span1>votre tilliste</Span1>{!isMobile() && <br />}
          <Span2>{name}</Span2>
        </Title1>
        <br />
        <LineH focus={!isMobile()} width={isMobile() ? '65%' : '110%'} /><br />
        <Title2><Span1 small>{toFormattedText(nickname)}</Span1></Title2>
      </TextContainer>
    </StyledContent>
  </Container>
);

OurArtisansHeaderBlock.propTypes = {
  headerImage: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
};

export default OurArtisansHeaderBlock;
